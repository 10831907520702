import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, BoxProps } from "@material-ui/core";

interface IProps extends BoxProps {
  label: string | any;
  text?: string | any;
  children?: any;
}

export default function LabelText({ children, label, text, ...rest }: IProps) {
  const cx = useStyles();

  if (children) {
    return (
      <Box className={cx.row} {...rest}>
        <span>{label}</span>
        <Box>{children}</Box>
      </Box>
    );
  }

  return text ? (
    <Box className={cx.row} {...rest}>
      <span>{label}</span>
      <p>{text}</p>
    </Box>
  ) : null;
}

const useStyles = makeStyles((theme) => ({
  row: {
    overflowWrap: "break-word",
    overflow: "hidden",
    flexWrap: "wrap",
    "& span": {
      fontSize: ".8rem",
      lineHeight: 1.5,
      "&::first-letter": {
        textTransform: "capitalize",
      },
    },
    "& p": {
      fontSize: "1rem",
      marginTop: 4,
      paddingLeft: 0,
      wordBreak: "break-word",
      display: "table-cell",
      fontWeight: 600,
      color: theme.palette.text.primary,
      lineHeight: 1.5,
      "&::first-letter": {
        textTransform: "capitalize",
      },
    },
  },
}));
