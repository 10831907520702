import { styled } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";

export const Text = styled(Box)((props: any) => ({
  lineHeight: "1.2em",
  fontSize: props.fontSize || "1rem",
  color: props.color || "#000",
}));

export const Flex = styled(Box)<BoxProps>({
  display: "flex",
  justifyItems: "center",
  flexWrap: "wrap",
  flexDirection: (props: any) => props.flexDirection || "column",
  width: (props: any) => props.width || "100%",
});
