export class NotificationService {
  notifcation: any;

  register(notifcation: any) {
    this.notifcation = notifcation;
  }

  show(content: any, type: "error" | "success" = "error", onClose?: any) {
    this.notifcation?.show(content, type, onClose);
  }

  hide() {
    this.notifcation.hide();
  }

  isShowing() {
    return this.notifcation.show;
  }
}

const notify = new NotificationService();
export default notify;
